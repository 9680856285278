const aboutUsLinks = [],
  spanishMegaMenusData = [
    // PERSONAL BANKING
    {
      id: "mm-pb-dropdown",
      labelId: "mm-pb-dropdown-label",
      englishTitle: "Personal Banking",
      title: "Banca personal",
      showScrollIcon: true,
      columns: [
        {
          id: "mmc-pb-checking",
          show: true,
          type: "links",
          class: "",
          englishTitle: "Checking",
          title: "Cuenta de cheques",
          url: "/es/banca-personal/cuenta-de-cheques",
          menus: [
            {
              id: "mml-pb-free-checking",
              show: true,
              englishText: "Free Checking",
              text: "Cuenta de cheques gratuitos",
              url: "/es/banca-personal/gratis-cuenta-de-cheques"
            },
            {
              id: "mml-pb-rewards-and-premium-rewards-checking",
              show: true,
              englishText: "Rewards and Premium Rewards Checking",
              text: "Rewards y Premium Rewards Checking",
              url: "/es/banca-personal/rewards-checking"
            },
            {
              id: "mml-pb-interest-checking",
              show: true,
              englishText: "Interest Checking",
              text: "Interest Checking",
              url: "/es/banca-personal/interest-rewards-checking"
            },
            {
              id: "mml-pb-fresh-start",
              show: true,
              englishText: "Fresh Start Account",
              text: "Cuenta Nuevo Comienzo (Fresh Start)",
              url: "/es/banca-personal/cuenta-nuevo-comienzo"
            }
          ]
        },
        {
          id: "mmc-pb-savings",
          show: true,
          type: "links",
          class: "",
          englishTitle: "Savings",
          title: "Cuentas de ahorro",
          url: "/es/banca-personal/cuentas-de-ahorro",
          menus: [
            {
              id: "mml-pb-savings-cds-money-market",
              show: true,
              englishText: "Savings, CD and Money Market",
              text: "Cuentas de ahorro,  cuentas a plazo fijo y cuentas Money Market",
              url: "/es/banca-personal/cuentas-de-ahorro"
            },
            {
              id: "mml-pb-retirement-planning",
              show: true,
              englishText: "Retirement Savings",
              text: "Cuentas individuales de retiro o jubilación (IRA)",
              url: "/es/banca-personal/jubilacion-ira"
            }
          ]
        },
        {
          id: "mmc-pb-mortgages",
          show: true,
          type: "links",
          class: "",
          englishTitle: "Mortgage",
          title: "Préstamos hipotecarios",
          url: "/es/banca-personal/prestamos-hipotecarios",
          menus: [
            {
              id: "mml-pb-home-loans-apply",
              show: true,
              englishText: "Apply for a Loan",
              text: "Contacte a un asesor hipotecario",
              url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
            },
            {
              id: "mml-pb-land-loans",
              show: true,
              text: "Préstamos para terrenos",
              url: "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos"
            },
            {
              id: "mml-pb-smart-start",
              show: true,
              text: "Hipoteca Smart Start",
              url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
            },
            {
              id: "mml-pb-homeowner-assistance",
              show: true,
              englishText: "Assistance",
              text: "Asistencia al propietario",
              url: "/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
            }
          ]
        },
        {
          id: "mmc-pb-credit-cards",
          show: true,
          type: "links",
          class: "",
          englishTitle: "Credit Cards",
          title: "Tarjetas de crédito",
          url: "/es/banca-personal/tarjetas-de-credito",
          menus: [
            {
              id: "mml-pb-about-credit-cards",
              show: true,
              englishText: "Credit Cards",
              text: "Tarjetas de crédito",
              url: "/es/banca-personal/tarjetas-de-credito"
            }
          ]
        },
        {
          id: "mmc-pb-personal-services",
          show: true,
          type: "links",
          class: "",
          englishTitle: "Banking With Us",
          title: "Servicios adicionales",
          menus: [
            {
              id: "mml-pb-account-rates",
              show: true,
              englishText: "Account Rates",
              text: "Tasas de interés",
              url: "/es/banca-personal/tasas-de-interes"
            },
            {
              id: "mml-pb-account-fees",
              show: true,
              englishText: "Account Fees",
              text: "Tarifas del consumidor",
              url: "/es/banca-personal/cargos"
            },
            {
              id: "mml-pb-mortgage-rates",
              show: true,
              englishText: "Mortgage Rates",
              text: "Tasas de interés para cuentas hipotecarias",
              url: "/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
            },
            {
              id: "mml-pb-faqs",
              show: true,
              englishText: "FAQs",
              text: "Preguntas frecuentes",
              url: "/es/preguntas-frecuentes"
            },
            {
              id: "mml-pb-contact-us",
              show: true,
              englishText: "Contact Us",
              text: "Contáctanos",
              url: "/es/contactanos"
            },
            {
              id: "mml-pb-money-tips",
              show: true,
              englishText: "WaFd Money Tips",
              text: "WaFd consejos de dinero",
              url: "/es/tu-decides"
            }
          ]
        }
      ]
    },
    {
      id: "mm-bb-dropdown",
      labelId: "mm-sb-dropdown-label",
      title: "Negocio",
      landingTitle: "Negocio",
      landingUrl: "/es/banca-de-negocios",
      showScrollIcon: false,
      columns: []
    },
    {
      id: "mm-ap-dropdown",
      labelId: "mm-ap-dropdown-label",
      title: "WaFd consejos de dinero",
      landingTitle: "WaFd Consejos de dinero",
      landingUrl: "/es/tu-decides",
      showScrollIcon: false,
      columns: []
    }
  ];

module.exports = {
  aboutUsLinks,
  spanishMegaMenusData
};
