import { useEffect, useState } from "react";
import useScreenSize from "./use-screen-size";

function useToggleMinimizeChat() {
  const isDesktop = useScreenSize("breakpointLg");

  const [chatIFrameFoundFirstTime, setChatIFrameFoundFirstTime] = useState(false);
  const [showingChat, setShowingChat] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  const handleWindowResize = (iframeContainerToHide) => {
    let isHidden = iframeContainerToHide.classList.contains("d-none");

    if (!isHidden) iframeContainerToHide.classList.add("d-none");

    if (!isDesktop) {
      // console.log("Is Mobile");
      if (showingChat) iframeContainerToHide.classList.remove("d-none");
      else iframeContainerToHide.classList.add("d-none");
    } else {
      // console.log("Is Desktop");
      iframeContainerToHide.classList.remove("d-none");
    }
  };

  // We handle the window resize change to show or hide the popup
  useEffect(() => {
    // console.log("isDesktop changed to:", isDesktop);
    if (!chatIFrameFoundFirstTime) {
      const iframePromise = new Promise((resolve) => {
        const observer = new MutationObserver((mutations) => {
          // console.log("Started mutation observer");
          const iframe = document.getElementById("lex-web-ui-iframe");
          if (iframe) {
            setChatIFrameFoundFirstTime(true);
            observer.disconnect();
            resolve(iframe);
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      });

      iframePromise.then((iframeContainerToHide) => {
        // console.log("Iframe found with mutation observer:", iframeContainerToHide);
        handleWindowResize(iframeContainerToHide);
      });
    } else {
      let iframeContainerToHide = document.getElementById("lex-web-ui-iframe");
      // console.log("Iframe found normally");
      handleWindowResize(iframeContainerToHide);
    }
  }, [isDesktop]);

  const host =
    typeof window !== `undefined`
      ? window.location.hostname === "www.wafdbank.com"
        ? "https://chat.wafdbank.com"
        : "https://chat-dev.pikestreet.io"
      : "https://chat-dev.pikestreet.io";

  const sendMessageToIframe = (messageChannel, evt) => {
    messageChannel.port1.close();
    messageChannel.port2.close();
    /* if (evt.data.event === "resolve") {
      console.log("iframe successfully handled our message", evt.data);
    } else {
      console.error("iframe failed to handle our message", evt.data);
    } */
  };

  const postMessage = () => {
    // console.log("Post message initialized...");
    // Start fixed code to use the postMessage in the iframe.
    let iframeElement = document.querySelector(".lex-web-ui-iframe iframe");
    let messageChannel = new MessageChannel();
    let message = { event: "toggleMinimizeUi" };
    messageChannel.port1.onmessage = (ev) => sendMessageToIframe(messageChannel, ev);
    // End fixed code to use the postMessage in the iframe.

    // Start custom logic and state handlers.
    let iframeContainerToHide = document.getElementById("lex-web-ui-iframe");

    let isMobile = !isDesktop ? true : false;
    // console.log("Current isMobile:", isMobile);

    let doShow =
      (iframeContainerToHide.classList.contains("lex-web-ui-iframe") &&
        !iframeContainerToHide.classList.contains("lex-web-ui-iframe--minimize") &&
        !iframeContainerToHide.classList.contains("lex-web-ui-iframe--show")) ||
      iframeContainerToHide.classList.contains("lex-web-ui-iframe--minimize")
        ? true
        : false;

    if (isMobile) {
      // console.log("Is mobile version");
      if (doShow) {
        // iframeContainerToHide.style.display = "flex";
        iframeContainerToHide.classList.remove("d-none");
      } else {
        // iframeContainerToHide.style.display = "none";
        iframeContainerToHide.classList.add("d-none");
      }
    }

    setShowingChat(doShow);
    // console.log("Current doShow:", doShow);
    // End custom logic and state handlers.

    // Fixed code to call the postMessage as required.
    iframeElement.contentWindow.postMessage(message, host, [messageChannel.port2]);
    // console.log("Post message finished...");
  };

  function handleChatScriptLoad() {
    // console.log("Hook -> AWS chat 2nd script loaded");
    var loaderOpts = {
      baseUrl: host,
      shouldLoadMinDeps: true
    };
    var loader = new ChatBotUiLoader.IframeLoader(loaderOpts);
    var chatbotUiConfig = {};
    loader
      .load(chatbotUiConfig)
      .then(function () {
        // console.log("chatbot UI loaded");
        // console.log("Chat clicked check passed");
        postMessage();
        setChatLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        // console.log("Chat clicked check not passed");
        setChatLoading(false);
      });
  }

  const runChatScriptLoad = () => {
    let script = document.createElement("script");
    script.src = `${host}/lex-web-ui-loader.min.js`;
    script.defer = true;
    script.onload = handleChatScriptLoad;
    document.body.appendChild(script);
  };

  const onClickOpenChat = async (chatLoading, setChatLoading) => {
    // console.log("Starting onClickOpenChat");
    if (chatLoading) {
      // console.log("Already started, call stopped");
      return;
    }

    setChatLoading(true);

    let iframeElement = document.querySelector(".lex-web-ui-iframe iframe");
    if (!iframeElement) {
      // console.log("Chat not found, loading chat...");
      await runChatScriptLoad();
    } else {
      postMessage();
      // console.log("Chat clicked check passed");
      setChatLoading(false);
    }

    // console.log("Finished onClickOpenChat");
  };

  const toggleChat = () => {
    onClickOpenChat(chatLoading, setChatLoading);
  };

  return { toggleChat, chatLoading };
}

export default useToggleMinimizeChat;
