const WaFdData = {
  tradingSymbol: "WAFD",
  brandNameShort: "WaFd",
  brandName: "WaFd Bank",
  brandNameLong: "Washington Federal Bank",
  presidentCEO: "Brent Beardall",
  phoneNumber: "800-324-9375",
  emailAddress: "info@wafd.com",
  mailingAddress: "425 Pike Street, Seattle, WA  98101",
  physicalAddress: "425 Pike Street, Seattle, WA  98101",
  routingNumber: "325070980",
  nmlsrNumber: "410394",
  urls: {
    publicWebsite: "https://wafdbank.com",
    spanishWebsite: "https://wafdbank.com/es",
    atmLocator: "/locations/atm",
    onlineBankingEnroll: "https://online.washingtonfederal.com/auth/Enrollment",
    onlineBankingForgottenUserId: "https://online.washingtonfederal.com/auth/ForgottenUserId",
    onlineBankingForgottenPassword: "https://online.washingtonfederal.com/auth/ForgottenPassword",
    onlineBankingLogin: "https://online.wafdbank.com",
    treasuryExpressLogin: "https://commercial.washingtonfederal.com/smallbusiness/auth",
    treasuryPrimeLogin: "https://commercial.washingtonfederal.com/",
    creditCardLogin: "https://www.myaccountaccess.com/onlineCard/login.do?theme=elan2&loc=14083",
    instantOpen: "https://forms.fivision.com/wafdbank/oa/default.aspx",
    mortgageApp: "https://apply.washingtonfederal.com/",
    jobSearch: "https://recruiting.adp.com/srccar/public/RTI.home?c=1155851&d=ExternalCareerSite",
    appleAppStore: "https://apps.apple.com/us/app/wafd-bank/id1054549841",
    googlePlayStore: "https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking",
    facebook: "https://www.facebook.com/wafdbank",
    twitter: "https://twitter.com/wafdbank",
    linkedIn: "https://linkedin.com/company/wafd-bank",
    youTube: "https://www.youtube.com/@WAFDBank?sub_confirmation=1"
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

export default WaFdData;
