import React, { useState } from "react";
import PropTypes from "prop-types";

// Styles
import "./notifications.bootstrap.scss";

import Icon from "../custom-widgets/icon";

const NotificationAlert = (props) => {
  let [showAlert, setShowAlert] = useState(true);

  const closeAlert = function () {
    setShowAlert(false);
  };

  const icon = {
    info: { lib: "fas", name: "info-circle" },
    warning: { lib: "fas", name: "exclamation-triangle" },
    danger: { lib: "fas", name: "exclamation-circle" },
    success: { lib: "fas", name: "check-circle" },
    primary: { lib: "fas", name: "info-circle" },
    secondary: { lib: "far", name: "question-circle" },
    light: { lib: "far", name: "question-circle" },
    dark: { lib: "far", name: "question-circle" }
  };

  const iconColorClass = {
    info: "text-primary",
    warning: "text-warning",
    danger: "text-danger",
    success: "text-success",
    primary: "text-primary",
    secondary: "text-gray-60",
    light: "text-gray-30",
    dark: "text-gray-90"
  };

  return (
    <div className={`notification-alert ${props.class}`}>
      {showAlert && (
        <div id={props.id} className={`alert alert-${props.type} d-flex align-items-start ${props.class}`}>
          <div className="d-flex h5-font-size">
            {props.showIcon && (
              <Icon
                class={`${iconColorClass[props.type]} mt-1 mr-3 fa-w-18`}
                name={icon[props.type].name}
                lib={icon[props.type].lib}
              />
            )}
            {props.bodyText && props.bodyText.trim() !== "" && (
              <div className="text-black notification-text" dangerouslySetInnerHTML={{ __html: props.bodyText }}></div>
            )}
            {props.bodyContent && <div className="text-black notification-text">{props.bodyContent}</div>}
          </div>
          {props.canClose && (
            <a
              className="text-black text-decoration-none ml-3"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                closeAlert();
              }}
            >
              <Icon class="text-secondary" name="times" />
            </a>
          )}
        </div>
      )}
    </div>
  );
};
export default NotificationAlert;

NotificationAlert.defaultProps = {
  type: "primary", // info, success, warning, danger, primary, secondary
  bodyText: "", // text rendered as HTML
  bodyContent: null, // any renderalbe React component or elements
  id: "notification-alert", // required to be unique if there are more than one NotificationAlert on a page
  class: "",
  showIcon: true,
  canClose: false
};

NotificationAlert.propTypes = {
  type: PropTypes.string,
  bodyText: PropTypes.string,
  bodyContent: PropTypes.node,
  id: PropTypes.string,
  class: PropTypes.string,
  showIcon: PropTypes.bool,
  canClose: PropTypes.bool
};
