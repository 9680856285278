/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
// NOTE: the languageContext is too slow for the menus, so using router location to check URL for ".../es/..."
import { useLocation } from "@reach/router";

// Components
import Icon from "../custom-widgets/icon";
import MobileMenuItems from "./mobile-menu-items";

// Menu Data
import { englishMobileMenusData, spanishMobileMenusData } from "../../data/mobile-menus-data";

// Styles
import "./mobile-menus.bootstrap.scss";

const MobileMenus = (props) => {
  // We are using the location.hash to check for hashchange events
  const location = useLocation();

  const [showingMobileMenus, setShowingMobileMenus] = useState(props.showMenu);

  // SET THE MENU DATA BY LANGUAGE by detecting the existence of ".../es[/...]" from the page URL
  // We are adding a trailing slash here so this can't be used to find the exact page URL
  let pagePath = useLocation().pathname;
  // Remove trailing slash (/) from the pagePath if it exists
  pagePath = pagePath.replace(/\/$/, "");
  // Now explicitly add a trailing slash to act as a word break for ".../es..."
  pagePath = pagePath + "/";
  const isSpanish = pagePath.indexOf("/es/") >= 0 ? true : false;

  const MENU_DATA = isSpanish ? spanishMobileMenusData : englishMobileMenusData;

  // This path is to compare the page URL and the URLs from the menusData to set the active state
  let path = typeof window !== "undefined" ? window.location.href.replace(window.location.origin, "") : "";
  let hash = location.hash;
  let i = path.indexOf("#");
  if (hash.includes("faq-answer")) {
    path = path.substring(0, i);
    // console.log("removed hash from path: " + path);
  }
  // Remove trailing slash (/) from the path if it exists
  path = path.replace(/\/$/, "");

  // Handle when user closes the menus
  const handleCloseMenu = () => {
    props.handleClose();
  };

  useEffect(() => {
    setTimeout(
      () => {
        setShowingMobileMenus(props.showMenu);
      },
      props.showMenu ? 0 : 500
    );
  }, [props.showMenu]);

  useEffect(() => {
    return () => {
      //console.log('The mobile menus component was unmounted');
      const body = document.body;
      body.style.overflow = "";
    }
  }, [])
  
  return (
    <>
      {/* Background */}
      <div
        onClick={() => handleCloseMenu()}
        id="background"
        className="sticky-top position-fixed w-100 vh-100"
        style={{
          top: 0,
          left: props.showMenu ? "0px" : "-100%",
          opacity: props.showMenu ? "100" : "0",
          transition: "opacity .5s",
          background: "rgba(0,0,0,.5)"
        }}
      ></div>
      {/* Menu Container */}
      <div
        id="mobile-menus"
        className="sticky-top position-fixed vh-100"
        style={{
          width: "87%",
          maxWidth: "400px",
          top: "0",
          left: props.showMenu ? "0px" : "-100%",
          transition: ".4s"
        }}
      >
        <div className="mobile-menu-close-btn-container" onClick={() => handleCloseMenu()}>
          <div
            onClick={() => handleCloseMenu()}
            id="mobile-menu-close-btn"
            className="bg-white py-3 px-4 d-inline-block cursor-pointer"
          >
            <Icon lib="fal" name="times" class="fa-lg text-secondary" />
          </div>
        </div>

        {showingMobileMenus && (
          <MobileMenuItems
            isSpanish={isSpanish}
            path={path}
            setShowMobileSearch={props.setShowMobileSearch}
            menuData={MENU_DATA}
          />
        )}
      </div>
      {/* END Menu Container */}
    </>
  );
};

export default MobileMenus;
