import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "@reach/router";
// Font Awesome Icons
import Icon from "../custom-widgets/icon";

import { useLanguageContext } from "../../contexts/language-context";

// React Bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

// Styles
import "./login.bootstrap.scss";

const Login = (props) => {
  const isSpanish = useLanguageContext();

  let path = useLocation().pathname;
  const [key, setKey] = useState(null);

  useEffect(() => {
    if (path.indexOf("business-banking") > 0 || path.indexOf("commercial-banking") > 0) {
      setKey("business");
      // Set Business tab dropdown with the default value
      setSelectedBizValue(valuesForBusinessSignIn[0]);
    } else {
      setKey("personal");
      // Set Personal tab dropdown with the default value (Online Banking in this case)
      setSelectedPerValue(valuesForPersonalSignIn[0]);
    }
  }, []);

  useEffect(() => {
    if (key === "business") setSelectedBizValue(valuesForBusinessSignIn[0]);
    else setSelectedPerValue(valuesForPersonalSignIn[0]);
  }, [key]);

  const [selectedPerValue, setSelectedPerValue] = useState("");
  const [selectedBizValue, setSelectedBizValue] = useState("");
  const handleSelectPersonalSignIn = (e) => {
    setSelectedPerValue(valuesForPersonalSignIn.find((n) => n.name === e));
  };
  const handleSelectBusinessSignIn = (e) => {
    setSelectedBizValue(valuesForBusinessSignIn.find((n) => n.name === e));
  };
  const valuesForBusinessSignIn = [
    {
      name: "",
      optionId: "default-option",
      buttonId: "default-sign-in-button",
      href: "/",
      enrollNowUrl: ""
    },
    {
      name: "Treasury Express",
      optionId: "treasury-express-option",
      buttonId: "treasury-express-sign-in-button",
      href: "https://commercial.washingtonfederal.com/smallbusiness/auth",
      enrollNowUrl: "https://business.washingtonfederal.com/enrollment"
    },
    {
      name: "Treasury Prime",
      optionId: "treasury-prime-option",
      buttonId: "treasury-prime-sign-in-button",
      href: "https://commercial.washingtonfederal.com",
      enrollNowUrl: ""
    },
    {
      name: "WaFd Client Connect",
      optionId: "client-connect-option",
      buttonId: "client-connect-sign-in-button",
      href: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5",
      enrollNowUrl: ""
    },
    {
      name: "Credit Cards",
      optionId: "credit-cards-option",
      buttonId: "credit-cards-sign-in-button",
      href: "https://www.ezcardinfo.com/?WAFD=/",
      enrollNowUrl: "https://www.ezcardinfo.com/?WAFD=/"
    },
    {
      name: "Credit Card Administration",
      optionId: "credit-cards-admin-option",
      buttonId: "credit-cards-admin-sign-in-button",
      href: "https://www.ezbusinesscardmanagement.com/bid/88003S",
      enrollNowUrl: "https://www.ezcardinfo.com/?WAFD=/"
    }
  ];

  const valuesForPersonalSignIn = [
    {
      name: "Online Banking",
      esName: "Banca en línea",
      optionId: "online-banking-option",
      buttonId: "online-banking-sign-in-button",
      href: "https://online.wafdbank.com/index.html",
      enrollNowUrl: "https://online.wafdbank.com/link/register?application=OLB",
      forgotUsernameUrl: "https://online.wafdbank.com/link/forgotusername?application=olb",
      forgotPasswordUrl: "https://online.wafdbank.com/link/forgotpassword?application=olb"
    },
    {
      name: "Credit Card",
      esName: "Tarjetas de crédito",
      optionId: "credit-card-option",
      buttonId: "credit-card-sign-in-button",
      href: "https://www.ezcardinfo.com/?WAFD=/",
      enrollNowUrl: "https://www.ezcardinfo.com/?WAFD=",
      forgotUsernameUrl: "https://www.ezcardinfo.com/login/enrollment-card/true/false",
      forgotPasswordUrl: "https://www.ezcardinfo.com/login/login-password"
    }
  ];

  const doesHaveEnrollOption = (values, type) => {
    let foundValue = values.find((element) => element.name === type);
    return foundValue == null ? false : foundValue.enrollNowUrl !== "" ? true : false;
  };

  const doesHaveForgotOption = (values, type) => {
    let foundValue = values.find((element) => element.name === type);
    return foundValue == null ? false : foundValue.forgotUsernameUrl !== "" ? true : false;
  };

  return (
    <div id={`${props.idPrefix}-login-box`} className="float-right dropdown-login-box bg-white shadow border-radius-12">
      <Tabs
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
        className="border-radius-top-12"
      >
        <Tab
          id={`${props.idPrefix}-personal-login-nav-tab`}
          eventKey="personal"
          title="Personal"
          className="text-secondary"
        >
          {/* Panel for logging into personal banking */}
          <div
            id={"personal-login-tab-content" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
            className="personal-login-panel border-radius-top-left-12 text-black"
          >
            <div className="p-3">
              <p className="h4 text-green-60">{isSpanish ? "Banca en línea" : "Online Banking"}</p>
              <Form.Group
                id={"personal-sign-in" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                className="dropdown"
              >
                <Form.Label>{isSpanish ? "Elige tu cuenta" : "Select your account"}</Form.Label>
                <Form.Control
                  id={"select-personal-account" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                  className="border-radius-6 select-input-text custom-select-chevron"
                  as="select"
                  defaultValue={""}
                  value={selectedPerValue.name}
                  onChange={(e) => handleSelectPersonalSignIn(e.target.value)}
                >
                  {valuesForPersonalSignIn.map((value, index) => (
                    <option
                      key={index}
                      value={value.name}
                      id={value.optionId + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                      className="dropdown-content"
                    >
                      {isSpanish ? value.esName : value.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <a
                className={`btn btn-block mb-3 ${!selectedPerValue.name ? "btn-disabled" : "btn-access-green"}`}
                href={selectedPerValue?.href}
                id={selectedPerValue?.buttonId + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
              >
                {isSpanish ? "Ingresar" : "Sign In"}
              </a>
              {doesHaveEnrollOption(valuesForPersonalSignIn, selectedPerValue.name) && (
                <p id={`${props.idPrefix}-first-time-signup-personal`} className="mb-1">
                  {isSpanish ? "Usuario nuevo? " : "First-time user? "}
                  <a
                    id={`${props.idPrefix}-enroll-link`}
                    className="text-underline"
                    href={selectedPerValue?.enrollNowUrl}
                  >
                    {isSpanish ? "Inscribirse" : "Enroll Now"}
                  </a>
                </p>
              )}
              {doesHaveForgotOption(valuesForPersonalSignIn, selectedPerValue.name) && (
                <p id={`${props.idPrefix}-forgot-username-password`} className="mb-1">
                  {isSpanish ? "Olvidó " : "Forgot  "}
                  <a
                    id={`${props.idPrefix}-forgot-username`}
                    className="text-underline"
                    href={selectedPerValue?.forgotUsernameUrl}
                  >
                    {isSpanish ? "su nombre de usuario" : "Username"}
                  </a>
                  {isSpanish ? " o " : " or "}
                  <a
                    id={`${props.idPrefix}-forgot-password`}
                    className="text-underline"
                    href={selectedPerValue?.forgotPasswordUrl}
                  >
                    {isSpanish ? "contraseña" : "Password"}
                  </a>
                  ?
                </p>
              )}
            </div>
            <div className="py-2 px-3 m-0 w-100 border-top list-unstyled bg-blue-60 border-radius-bottom-12">
              <a
                className="text-sm text-white text-decoration-none"
                href={isSpanish ? "/es/abrir-cuenta-bancaria-en-linea" : "/open-bank-account-online"}
                id={`${props.idPrefix}-open-account-personal`}
              >
                <span className="font-weight-semi-bold">
                  {isSpanish ? "Nuevo en WaFd Bank? " : "New to WaFd Bank? "}
                </span>
                <span className="text-nowrap">
                  {isSpanish ? "Abrir una cuenta" : "Open an Account"}
                  <Icon lib="fal" name="arrow-right" class="text-white ml-2 mt-1" />
                </span>
              </a>
            </div>
          </div>
        </Tab>

        {/* Panel for logging into business banking */}
        {!isSpanish && (
          <Tab
            id={`${props.idPrefix}-business-login-nav-tab`}
            eventKey="business"
            title="Business"
            className="text-secondary"
          >
            <div
              id={"business-login-tab-content" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
              className="text-black"
            >
              <div className="p-3">
                <p className="h4 text-green-60">Business &amp; Commercial</p>
                <Form.Group
                  id={"business-commercial-sign-in" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                  className="dropdown"
                >
                  <Form.Label>Select your account</Form.Label>
                  <Form.Control
                    id={"select-biz-account" + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                    className="border-radius-6 select-input-text custom-select-chevron"
                    as="select"
                    defaultValue={""}
                    value={selectedBizValue.name}
                    onChange={(e) => handleSelectBusinessSignIn(e.target.value)}
                  >
                    {valuesForBusinessSignIn.map((value, index) => (
                      <option
                        key={index}
                        value={value.name}
                        id={value.optionId + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                        className="dropdown-content"
                      >
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <a
                  className={`btn btn-block mb-3 ${!selectedBizValue.name ? "btn-disabled" : "btn-access-green"}`}
                  href={selectedBizValue?.href}
                  id={selectedBizValue?.buttonId + (props.idPrefix === "mobile" ? "-" + props.idPrefix : "")}
                >
                  Sign In
                </a>
                {doesHaveEnrollOption(valuesForBusinessSignIn, selectedBizValue.name) && (
                  <p id={`${props.idPrefix}-first-time-signup-personal`} className="mb-0">
                    First-time user?&nbsp;
                    <a
                      id={`${props.idPrefix}-business-enroll-link`}
                      className="text-underline"
                      href={selectedBizValue.enrollNowUrl}
                    >
                      Enroll Now
                    </a>
                  </p>
                )}
                <p className="mb-0 mt-2">
                  <a
                    id={`${props.idPrefix}-personal-banking-link`}
                    className="text-underline my-2"
                    href="https://online.wafdbank.com/index.html"
                  >
                    Using Personal Banking for Business?
                  </a>
                </p>
              </div>
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Login;
Login.defaultProps = {
  idPrefix: "default" // "default" or "mobile"
};
