import React, { useEffect, useRef, useState } from "react";
import Button from "../custom-widgets/button";

const MobileSubMenuItems = ({ path, aboveMenuContainerRef, menuData }) => {
  const hasSubMenuItems = menuData?.menus?.length ? true : false;
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuItemsContainerRef = useRef(null);

  useEffect(() => {
    //console.log("Sub Menu Data:", menuData);
    if (subMenuItemsContainerRef.current) {
      if (showSubMenu) {
        subMenuItemsContainerRef.current.style.left = 0;
        aboveMenuContainerRef.current.style.overflowY = "hidden";
      }
    }
  }, [showSubMenu]);

  const isActive = (isLink, itemUrl) => {
    return isLink ? itemUrl === path : false;
  };

  const MAIN_BUTTON_PROPS = {
    id: menuData.id,
    type: hasSubMenuItems ? "button" : menuData?.externalLink ? "anchor" : "link",
    text: menuData.title,
    containerClass: "w-100",
    class:
      "w-100 d-flex justify-content-between text-left font-weight-normal p-3 border-bottom menu-item-bg " +
      (isActive(!hasSubMenuItems, menuData.url) ? "active" : ""),
    showIcon: hasSubMenuItems,
    icon: {
      position: "right",
      lib: "far",
      name: "chevron-right",
      class: "float-right mt-1 ml-3 text-secondary"
    },
    url: menuData.url,
    onClick: () => {
      if (hasSubMenuItems) setShowSubMenu(true);
    }
  };

  const BACK_BUTTON_PROPS = {
    type: "button",
    text: "Back",
    containerClass: "w-100 border-bottom back-btn-container",
    class: "back-btn p-3 menu-item-bg font-weight-normal",
    icon: {
      position: "left",
      lib: "far",
      name: "chevron-left",
      class: "float-left mt-1 mr-2 text-primary"
    },
    onClick: () => {
      subMenuItemsContainerRef.current.style.left = "100%";
      aboveMenuContainerRef.current.style.overflowY = "";
      setTimeout(() => {
        setShowSubMenu(false);
      }, 500);
    }
  };

  const CATEGORY_MAIN_BUTTON_PROPS = {
    id: menuData.id + "-label",
    type: menuData?.externalLink ? "anchor" : "link",
    text: menuData?.landingTitle || menuData.title,
    containerClass: "w-100",
    class:
      "w-100 d-flex justify-content-between text-left font-weight-bolder p-3 border-bottom menu-item-bg " +
      (isActive(true, menuData.url) ? "active" : ""),
    showIcon: false,
    url: menuData.url
  };

  const VISIBLE_SUB_MENU_ITEM_PROPS = (itemData) => {
    return {
      id: itemData.id,
      type: itemData?.externalLink ? "anchor" : "link",
      text: itemData.title,
      containerClass: "w-100",
      class:
        "w-100 d-flex justify-content-between text-left font-weight-normal p-3 pl-4 menu-item-bg " +
        (isActive(true, itemData.url) ? "active" : ""),
      showIcon: false,
      url: itemData.url
    };
  };

  return (
    <React.Fragment key={menuData.id}>
      {!menuData.showMenuItems ? (
        <>
          <Button {...MAIN_BUTTON_PROPS} />

          {hasSubMenuItems && showSubMenu && (
            <div
              id={menuData.id}
              className="mobile-sub-menu-items-container"
              ref={subMenuItemsContainerRef}
              key={menuData.id}
            >
              <Button {...BACK_BUTTON_PROPS} />

              <Button {...CATEGORY_MAIN_BUTTON_PROPS} />

              {menuData.menus.map((menuItem) => {
                return (
                  <React.Fragment key={menuItem.id}>
                    <MobileSubMenuItems
                      aboveMenuContainerRef={subMenuItemsContainerRef}
                      path={path}
                      menuData={menuItem}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <>
          <div id={menuData.id} className="w-100 d-flex justify-content-between text-left font-weight-bolder p-3 text-success">
            <p className="mb-0">{menuData.title}</p>
          </div>
          {hasSubMenuItems &&
            menuData.menus.map((menuItem) => {
              return (
                <React.Fragment key={menuItem.id}>
                  <Button {...VISIBLE_SUB_MENU_ITEM_PROPS(menuItem)} />
                </React.Fragment>
              );
            })}
        </>
      )}
    </React.Fragment>
  );
};

export default MobileSubMenuItems;
