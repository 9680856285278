import React from "react";
import { Link } from "gatsby";

const FooterList = (props) => (
  <ul className="nav flex-column mr-3 mb-3 mb-lg-0">
    <li className="nav-item mb-2 pb-2 text-sm">
      <strong>
        <Link id={props.id} to={props.url}>
          {props.title}
        </Link>
      </strong>
    </li>
    {props.links.map((item) => (
      <li key={item.id} className="nav-item pb-2 text-sm">
        {item.externalLink ? (
          <a href={item.url} id={item.id} target="_blank" rel="noopener noreferrer" className="text-secondary">
            {item.text}
          </a>
        ) : (
          <Link id={item.id} to={item.url} className="text-secondary">
            {item.text}
          </Link>
        )}
      </li>
    ))}
  </ul>
);

export default FooterList;
