import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { useLanguageContext } from "../../contexts/language-context";
import WafdData from "../../data/wafd-data";
import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";

// Components
import MegaMenus from "../mega-menus/mega-menus";
import MobileMenus from "../mega-menus/mobile-menus";
import Modal from "react-bootstrap/Modal";
import Login from "../login/login";
import Button from "../custom-widgets/button";
import SearchSuggestionsInput from "../custom-widgets/search-suggestions-input";

// React Bootstrap
import { CloseButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

// Styles
import styles from "./header.module.scss";
import "./header.bootstrap.scss";

// Images
import Logo from "../../assets/wafd-bank-logo.svg"; //"../../images/wafd-logo.png";
import Logo16x16 from "../../assets/wafd-bank-logo-16x16.svg";

// Font Awesome Icons
import Icon from "../custom-widgets/icon";

import useToggleMinimizeChat from "../../hooks/use-toggle-minimize-chat";

const Header = () => {
  const isSpanish = useLanguageContext();
  let path = useLocation().pathname;

  // Remove trailing slash (/) from the URL
  path = decodeURI(path).replace(/\/$/, "");

  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState("");
  const [querySuggestions, setQuerySuggestions] = useState({ hits: [] });

  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const searchRef = useRef(null);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const handleCloseSearchModal = () => setShowSearchModal(false);
  const handleShowSearchModal = (e) => {
    e.preventDefault();
    setShowSearchModal(true);
    setQuerySuggestions({ hits: [] });
  };

  const handleDropdownToggle = (isOpen, event, metadata) => {
    if (metadata.source === "click") {
      setDropdownToggle(!dropdownToggle); // true
    }
  };
  const handleMenuToggle = (expanded) => {
    if (expanded) {
      setMenuToggle(false);
    }
  };

  const handleShowMobileMenu = (show) => {
    const body = document.body;
    body.style.overflow = show ? "hidden" : "";
    setShowMobileMenu(show);
  };

  const handleCloseMobileSearch = (value) => {
    setShowMobileSearch(value);
    setShowMobileMenu(value);
    setQuerySuggestions({ hits: [] });
    setQuery("");
  };

  // Update Open an Account button URL depends on the page the user is on

  function openAnAccountUrl() {
    if (path === "/open-bank-account-online") {
      return "/open-an-account";
    } else if (path === "/business-banking/open-business-bank-account-online") {
      return "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5";
    } else if (path.indexOf("business-banking") > 0 || path.indexOf("commercial-banking") > 0) {
      return "/business-banking/open-business-bank-account-online";
    } else return "/open-bank-account-online";
  }

  const openAccountBtn = {
    type: "link",
    text: "Open an Account",
    id: "open-account-button",
    url: openAnAccountUrl(),
    showIcon: false,
    class: "btn-link no-min-width"
  };

  const spanishOpenAccountBtn = {
    id: "open-account-button",
    type: "link",
    text: "Abrir una cuenta",
    url: "/es/abrir-cuenta-bancaria-en-linea",
    showIcon: false,
    class: "btn-link no-min-width"
  };

  const loginDropdownRef = useRef();
  const mobileLoginDropdownRef = useRef();
  const headerRef = useRef();

  useEffect(() => {
    function handler(event) {
      if (
        !loginDropdownRef.current?.contains(event.target) &&
        !mobileLoginDropdownRef.current?.contains(event.target)
      ) {
        setDropdownToggle(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  // Alternate URL Functionality (Start)

  const [altUrl, setAltUrl] = useState(null);

  useEffect(() => {
    const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
    const newUrl = urlMap[path];

    if (newUrl) {
      setAltUrl(newUrl);
    } else {
      setAltUrl(isSpanish ? "/" : "/es");
    }
  });

  // Alternate URL Functionality (End)

  const { toggleChat, chatLoading } = useToggleMinimizeChat();

  return (
    <header id="primary-nav-header" className="sticky-top bg-white shadow" ref={headerRef}>
      <div className="container">
        <div className="p-0">
          {/* ---------- XS - MD ---------- */}
          <div
            id="header-mega-menus-mobile-container"
            className="d-flex d-lg-none justify-content-between align-items-center w-100"
          >
            {/* HAMBURGER (MOBILE) */}
            <p
              id="mobile-menu-open-cta"
              className="text-dark px-2 py-1 mb-0 cursor-pointer"
              aria-label="Toggle navigation"
              onClick={() => handleShowMobileMenu(true)}
            >
              <Icon name="bars" class="fa-lg text-secondary" />
              <span className="ml-2 text-secondary d-none d-xs-inline">{isSpanish ? "Menú" : "Menu"}</span>
            </p>

            {/* LOGO (MOBILE) */}
            <div className="mobile-logo-container">
              <Link id="mobile-header-logo-link" to={isSpanish ? "/es" : "/"} className="navbar-brand py-2 mr-0">
                <img
                  id="header-wafd-bank-mobile-logo"
                  width={240}
                  height={55}
                  src={Logo}
                  className={`img-responsive ${styles.brandLogo}`}
                  alt="WaFd Bank logo"
                />
              </Link>
            </div>

            {/* SEARCH BUTTON & DROPDOWN LOGIN FORM (MOBILE) */}
            <div id="header-buttons-container" className="d-flex align-items-center">
              {/* CHAT (ICON) BUTTON */}
              <button
                id="header-chat-button-m"
                disabled={chatLoading}
                className={`btn-chat px-2 text-secondary`}
                onClick={toggleChat}
              >
                {chatLoading ? (
                  <img
                    id="header-wafd-bank-mobile-loading-logo"
                    src={Logo16x16}
                    className="btn-chat-logo-loading"
                    alt="WaFd Bank logo loading"
                  />
                ) : (
                  <Icon lib="fas" title="Chat with Walt" name="comment-smile" class="text-green-60" />
                )}
              </button>

              <Dropdown alignRight show={dropdownToggle} onToggle={handleDropdownToggle} ref={mobileLoginDropdownRef}>
                <Dropdown.Toggle variant="none" id="mobile-login-button">
                  <Icon lib="fal" name="user" class="fa-lg text-secondary" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-radius-12">
                  <Dropdown.Item as={() => <Login idPrefix="mobile" />} />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <MobileMenus
            showMenu={showMobileMenu}
            handleClose={() => handleShowMobileMenu(false)}
            setShowMobileSearch={setShowMobileSearch}
          />

          {dropdownToggle && (
            <div
              id="background-sign-in-activated"
              className="sticky-top position-absolute w-100 vw-100"
              style={{
                left: "0",
                height: "100vh",
                width: "100vw",
                zIndex: "1",
                top: 0,
                opacity: "100",
                transition: "opacity .5s",
                background: "rgba(0,0,0,.5)"
              }}
            />
          )}

          {/* ---------- LG - XL ---------- */}
          <div className="d-none d-lg-block">
            {/* LOGO */}
            <div id="header-logo-container" className="d-flex align-items-start justify-content-center pt-3">
              <Link id="header-logo-link" to={isSpanish ? "/es" : "/"} className="mr-5">
                <img
                  id="header-wafd-bank-logo"
                  width={240}
                  height={55}
                  src={Logo}
                  className={`img-responsive ${styles.brandLogo}`}
                  alt="WaFd Bank logo"
                />
              </Link>
              <div id="header-right-container" className="d-flex align-items-start justify-content-end flex-fill">
                {/* TOP 3 LINKS (Spanish, locations, ATMs) */}
                <nav
                  id="header-top-links-container"
                  role="navigation"
                  aria-label="Search, Locations, Español"
                  className="navbar px-0"
                >
                  <ul className="nav nav-pills align-items-center">
                    <li className="mr-3">
                      {/* CHAT (ICON) BUTTON */}
                      <button
                        id="header-chat-button"
                        className={`btn-chat text-secondary`}
                        disabled={chatLoading}
                        onClick={toggleChat}
                      >
                        {chatLoading ? (
                          <img
                            id="header-wafd-bank-loading-logo"
                            src={Logo16x16}
                            className="btn-chat-logo-loading mr-2"
                            alt="WaFd Bank logo loading"
                          />
                        ) : (
                          <Icon lib="fas" title="Chat with Walt" name="comment-smile" class="mr-2 text-green-60" />
                        )}
                        Chat
                      </button>
                    </li>
                    <li className="nav-item mr-3">
                      {/* SEARCH (ICON) BUTTON */}
                      <div className={`nav-item my-auto`}>
                        <a
                          onClick={handleShowSearchModal}
                          className="my-n2 text-secondary"
                          aria-label="Search"
                          href="/#"
                          id="header-search-button"
                        >
                          <Icon name="search" class="mr-2" />
                          {isSpanish ? "Buscar" : "Search"}
                        </a>
                      </div>
                    </li>
                    <Modal show={showSearchModal} onHide={handleCloseSearchModal}>
                      <Modal.Header className="border-0 text-center">
                        <Modal.Title id="ssm-title" className="mb-0 h5" style={{ paddingLeft: 2 }}>
                          {isSpanish ? "Buscar WaFd Bank" : "Search WaFd Bank"}
                        </Modal.Title>
                        <CloseButton id="ssm-close-button" onClick={handleCloseSearchModal} />
                      </Modal.Header>
                      <Modal.Body className="pt-0">
                        <SearchSuggestionsInput
                          type="modal"
                          query={query}
                          setQuery={setQuery}
                          querySuggestions={querySuggestions}
                          setQuerySuggestions={setQuerySuggestions}
                          handleCloseSearchModal={handleCloseSearchModal}
                        />
                      </Modal.Body>
                    </Modal>
                    <li className="nav-item mr-3">
                      {isSpanish ? (
                        <Link to="/es/sucursales" className="text-secondary p-2" id="header-es-locations-link">
                          <Icon name="map-marker-alt" class="mr-2" />
                          Sucursales
                        </Link>
                      ) : (
                        <Link to="/locations" className="text-secondary p-2" id="header-en-locations-link">
                          <Icon name="map-marker-alt" class="mr-2" />
                          Locations
                        </Link>
                      )}
                      <span className="text-gray-30">|</span>
                      <Link to={WafdData.urls.atmLocator} className="text-secondary py-2 px-2" id="header-atms-link">
                        {isSpanish ? "ATMs" : "ATMs"}
                      </Link>
                    </li>

                    {altUrl && (
                      <li className="nav-item mr-3">
                        {isSpanish ? (
                          <Link to={altUrl} className="text-secondary py-2" id="header-english-link" lang="en">
                            <Icon name="globe" class="mr-2" />
                            English
                          </Link>
                        ) : (
                          <Link to={altUrl} className="text-secondary py-2" id="header-spanish-link" lang="es">
                            <Icon name="globe" class="mr-2" />
                            Español
                          </Link>
                        )}
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>

            <div id="header-mega-menus-container" className="d-flex align-items-between justify-content-between">
              {/* MEGA-MENUS (DESKTOP) */}
              <MegaMenus path={path} onMenuToggle={handleMenuToggle} />

              <div id="header-buttons-container" className="d-flex align-items-end justify-content-end">
                <div id="header-open-account-cta" className="mr-2">
                  {isSpanish ? <Button {...spanishOpenAccountBtn} /> : <Button {...openAccountBtn} />}
                </div>
                {/* SIGN IN BUTTON & DROPDOWN LOGIN FORM */}
                <div id="header-login-container" className="ml-2">
                  <Dropdown alignRight show={dropdownToggle} onToggle={handleDropdownToggle} ref={loginDropdownRef}>
                    <Dropdown.Toggle variant="" className="btn-access-green" id="default-login-button">
                      {isSpanish ? "Ingresar" : "Sign In"} <Icon lib="fas" name="chevron-down" class="ml-2 mt-1" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border-radius-12">
                      <Dropdown.Item as={() => <Login />} className="border-radius-12" />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {/* END LG - XL .colapse */}
          </div>
        </div>
        {/* END .navbar */}
      </div>
      {/* END .container */}

      {/* MOBILE SEARCH (Start) */}
      {/* Background */}
      <div
        onClick={() => setShowMobileSearch(false)}
        id="background"
        className="sticky-top position-fixed w-100 vh-100"
        style={{
          top: 0,
          left: showMobileSearch ? "0px" : "-100%",
          opacity: showMobileSearch ? "100" : "0",
          transition: "opacity .5s",
          background: "rgba(0,0,0,.5)"
        }}
      ></div>
      {/* Menu Container */}
      <div
        id="search-mobile"
        className="sticky-top position-fixed vh-100"
        style={{
          width: "87%",
          maxWidth: "400px",
          top: "0",
          left: showMobileSearch ? "0px" : "-100%",
          transition: ".4s"
        }}
      >
        <div className="position-sticky vh-100">
          <div className="mobile-menu-close-btn-container" onClick={() => setShowMobileSearch(false)}>
            <div
              onClick={() => setShowMobileSearch(false)}
              id="mobile-menu-close-btn"
              className="bg-white py-3 px-4 d-inline-block cursor-pointer"
            >
              <Icon lib="fal" name="times" class="fa-lg text-secondary" />
            </div>
          </div>
          <div className="pt-3 px-2 bg-white h-100 overflow-auto" style={{ paddingBottom: "110px" }}>
            <SearchSuggestionsInput
              type="mobile"
              query={query}
              setQuery={setQuery}
              querySuggestions={querySuggestions}
              setQuerySuggestions={setQuerySuggestions}
              showMobileSearch={showMobileSearch}
              setShowMobileSearch={setShowMobileSearch}
              handleCloseMobileSearch={handleCloseMobileSearch}
            />
          </div>
        </div>
      </div>
      {/* MOBILE SEARCH (End) */}
    </header>
  );
};

export default Header;
