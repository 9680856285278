import React from "react";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";
import { useLanguageContext } from "../../contexts/language-context";

const MobileMenusSectionAboveMenus = ({ altUrl, setShowMobileSearch }) => {
  const isSpanish = useLanguageContext();

  return (
    <div className="position-sticky">
      <div className="bg-white d-flex align-content-center justify-content-between border-bottom">
        <div className="w-100 p-3">
          {isSpanish ? (
            <div className="w-100 d-flex align-items-center justify-content-between">
              <button
                id="mm-activate-search-modal"
                onClick={() => setShowMobileSearch(true)}
                className="btn-anchor-link"
              >
                <Icon lib="fal" name="search" />
              </button>
              <div className="d-flex align-items-center">
                <Icon class="text-primary mr-1" name="map-marker-alt" />
                <Link to="/es/sucursales" id="mm-es-locations-link">
                  Sucursales
                </Link>{" "}
                |{" "}
                <Link to="/locations/atm" id="mm-atms-link">
                  ATMs
                </Link>
              </div>
              <div className="text-nowrap">
                <Icon lib="far" name="globe" class="text-primary mr-1" />
                <Link to={altUrl} id="mm-english-link">
                  English
                </Link>
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex align-items-center justify-content-between">
              <button
                id="mm-activate-search-modal"
                onClick={() => setShowMobileSearch(true)}
                className="btn-anchor-link"
              >
                <Icon lib="fal" name="search" />
              </button>
              <div className="d-flex align-items-center">
                <Icon lib="far" name="map-marker-alt" class="text-primary mr-1" />
                <Link to="/locations" id="mm-en-locations-link">
                  Locations
                </Link>{" "}
                |{" "}
                <Link to="/locations/atm" id="mm-atms-link">
                  ATMs
                </Link>
              </div>
              <div className="text-nowrap">
                <Icon lib="far" name="globe" class="text-primary mr-1" />
                <Link to={altUrl} id="mm-spanish-link">
                  Español
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenusSectionAboveMenus;
