import React, { useEffect, useRef, useState } from "react";
import MobileSubMenuItems from "./mobile-sub-menu-items";
import Button from "../custom-widgets/button";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";
import MobileMenusSectionAboveMenus from "./mobile-menus-section-above-menus";

// URL Data
import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";

const MobileMenuItems = ({ isSpanish, path, setShowMobileSearch, menuData }) => {
  const mobileMenuItemsContainerRef = useRef(null);
  // Alternate URL Functionality (Start)

  const [altUrl, setAltUrl] = useState(null);

  useEffect(() => {
    //console.log("isSpanish: ", isSpanish);
    const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
    const newUrl = urlMap[path];

    if (newUrl) {
      setAltUrl(newUrl);
    } else {
      setAltUrl(isSpanish ? "/" : "/es");
    }
  });

  // Alternate URL Functionality (End)

  const openAnAccountUrl = () => {
    // OLD CODE AS REFERENCE
    /*if (path === "/open-bank-account-online") {
      return "/open-an-account";
    } else if (path === "/business-banking/open-business-bank-account-online") {
      return "https://forms.fivision.com/wafdbank/oba/Default.aspx";
    } else if (path.indexOf("business-banking") > 0 || path.indexOf("commercial-banking") > 0) {
      return "/business-banking/open-business-bank-account-online";
    } else return "/open-bank-account-online";*/

    const businessCommercialBanking =
      path.indexOf("business-banking") > 0 || path.indexOf("commercial-banking") > 0 ? true : false;

    const OPEN_ACCOUNT_LINKS = {
      "/open-bank-account-online": "/open-an-account",
      "/business-banking/open-business-bank-account-online":
        "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5"
    };

    const DEFAULT_URL = "/open-bank-account-online";

    const link = businessCommercialBanking
      ? "/business-banking/open-business-bank-account-online"
      : OPEN_ACCOUNT_LINKS[path] || DEFAULT_URL;
    //console.log("Returned link:", link);
    return link;
  };

  const oabUrl = openAnAccountUrl();
  const isExternalLink = oabUrl.startsWith("http") || oabUrl.startsWith("https");
  const openAccountBtn = {
    id: "mm-open-account-button",
    type: isExternalLink ? "anchor" : "link",
    text: "Open an Account",
    url: oabUrl,
    showIcon: false,
    class: "btn btn-primary d-block mb-3"
  };

  return (
    <div
      id="mobile-menu-items-main"
      className="mobile-menu-items-container"
      key="mobile-menu-items-main"
      ref={mobileMenuItemsContainerRef}
    >
      <MobileMenusSectionAboveMenus altUrl={altUrl} setShowMobileSearch={setShowMobileSearch} />

      {menuData.map((menuItem) => {
        return (
          <MobileSubMenuItems aboveMenuContainerRef={mobileMenuItemsContainerRef} path={path} menuData={menuItem} />
        );
      })}
      <div className="p-3 bg-white">
        {isSpanish ? (
          <Link
            to="/es/abrir-cuenta-bancaria-en-linea"
            className="btn btn-primary d-block mb-3"
            id="mm-open-account-button"
          >
            Abrir una cuenta
          </Link>
        ) : (
          <>
            <Button {...openAccountBtn} />
            <Link
              to="/personal-banking/contact-loan-officer"
              className="btn btn-primary d-block mb-3"
              id="mm-apply-for-a-loan-button"
            >
              Apply for a Loan
            </Link>

            <div>
              <Icon lib="far" name="mobile" class="text-primary mr-2" />
              <Link to="/personal-banking/online-banking#mobile-app-section" id="mm-mobile-banking-app-link">
                Get the WaFd Mobile app
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileMenuItems;
