import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import globalVariables from "../styles/global-variables.module.scss";

/**
 * This hook checks if the screen size is bigger or equal than the screen size sent
 * 
 * @param {string} screenSize - (Required) Screen Size variable name ["breakpointXs", "breakpointSm", "breakpointMd", "breakpointLg", "breakpointXl", "breakpointXxl"]
 * @returns {boolean} Returns true or false if the screen size is bigger or equal than the screen size sent
 */
function useScreenSize(screenSize) {
  const [screenSizeMatch, setScreenSizeMatch] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // We use the import of the globalVariables to get the breakpoints and use them in our logic
      const doesMatch = window.innerWidth >= parseInt(globalVariables[screenSize], 10);
      setScreenSizeMatch(doesMatch);
      // console.info("MATCH:", doesMatch);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return screenSizeMatch;
}

export default useScreenSize;

useScreenSize.defaultProps = {
  screenSize: "breakpointMd"
};

useScreenSize.propTypes = {
  screenSize: PropTypes.string.isRequired,
};